@use '@angular/material' as mat;
@use './glotto-theme';
@use './styles/fonts/poppins' as fonts;

:root {
  --gap: 20px;
  --padding: 20px;
  --rounded-corners: 20px;
  --max-width: 1200px;

  --border-color: color-mix(in srgb, var(--mat-app-text-color) 25%, transparent);
}

html {
  height: 100%;
  
  @include mat.elevation-classes();
  @include mat.all-component-themes(glotto-theme.$light-theme);
}

body {
  @include fonts.poppins-light;

  margin: 0;
  padding: 0;
  
  height: 100%;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  @include fonts.poppins-regular;
}

p {
  @include fonts.poppins-regular;
  white-space: pre-line;
}

.dark-mode {
  --border-color: color-mix(in srgb, var(--mat-app-text-color) 25%, transparent);

  @include mat.all-component-colors(glotto-theme.$dark-theme);
}

.spacer {
  flex: 1 1 auto;
}

.route-active {
  span {
      @include fonts.poppins-semibold;
  }
}

mat-card {
  padding: var(--padding);
  border-radius: var(--rounded-corners);
  background-color: var(--surface);
}
