@mixin poppins-light {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

@mixin poppins-regular {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

@mixin poppins-semibold {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
} 